<template>
  <div class="view-wrapper">
    <floating-menu/>
    <card-detail v-if="selectedCard"
                 :card="selectedCard"
                 @navigateCard="navigateCard($event)"
                 @close="selectCard(null)"/>
    <div class="selection-panel">
      <div class="suit-selection">
        <div class="suit-selector suit-bg"
             :class="[suit, {selected: selectedSuit === suit}]"
             v-for="suit in suits"
             :key="suit"
             @click="selectSuit(suit)">
          <label>{{ $t('suits.' + suit) }}</label>
        </div>
        <div class="suit-selector cursed-item-bg"
             v-if="cursedItemsEnabled"
             @click="selectCursedItems">
          <label class="cursed-item-text">{{ $t('cursedItems') }}</label>
        </div>
      </div>
      <div class="card-selection suit-bg"
           v-if="selectedSuit"
           :class="selectedSuit">
        <div v-for="card in selectableCardsInSelectedSuit"
             :key="card.name"
             class="card-selector"
             :style="{maxWidth: (95 / cardsInSelectedSuit.length) + '%',
             minWidth: (95 / cardsInSelectedSuit.length) + '%'}">
          <card-image :card="card"
                      height="130%"
                      @select="addCardToPile({cardName: card.name, pile: selectedPile}).catch(error => showWarning(error.message))"/>
        </div>
        <div v-for="index in (maxCardsPerSuit - cardsInSelectedSuit.length)"
             :key="index"
             class="card-selector"
             :style="{flexGrow: 1, flexBasis: '100%', maxWidth: 95 / cardsInSelectedSuit.length + '%'}">
        </div>
      </div>
      <cursed-items-panel
          v-else-if="cursedItemsEnabled"/>
    </div>
    <div class="pile-panel">
      <div class="heading">
        <players-panel class="players-panel"/>
        <score class="score-panel"/>
      </div>
      <div class="text"
           v-if="cardsInSelectedPile.length === 0">
        <span v-if="selectedPile > 0">{{ $t('chooseHandCardsForPlayer', {player: selectedPlayer.name}) }}</span>
        <span v-if="selectedPile === 0">{{ $t('chooseHandCardsForDiscard') }}</span>
      </div>
      <div class="pile"
           v-else>
        <div v-for="card in cardsInSelectedPile"
             :key="'pile_' + card.name"
             class="card"
             :style="{maxWidth: (90 / maximumCardsInSelectedPile) + '%', minWidth: (90 / maximumCardsInSelectedPile) + '%'}">
          <card-image :card="card"
                      height="100%"
                      @select="selectCard(card)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PlayersPanel from "@/components/PlayersPanel";
import CardImage from "@/components/CardImage2";
import CardDetail from "@/components/CardDetail";
import Score from "@/components/Score";
import FloatingMenu from "@/components/FloatingMenu";
import CursedItemsPanel from "@/components/CursedItemsPanel";

export default {
  components: {CursedItemsPanel, Score, CardDetail, CardImage, PlayersPanel, FloatingMenu},
  data() {
    return {
      selectedSuit: '',
      cursedItemsSelected: false
    }
  },
  computed: {
    ...mapGetters(['allCards', 'cardsInSelectedPile', 'selectedPile', 'freshGame', 'selectedCard',
      'maximumCardsInSelectedPile',
      'cursedItemsEnabled',
      'cursedItems',
      'selectedPlayer']),
    suits() {
      return new Set(this.allCards.map(c => c.suit))
    },
    cardsInSelectedSuit() {
      return this.allCards.filter(c => c.suit === this.selectedSuit)
    },
    selectableCardsInSelectedSuit() {
      return this.cardsInSelectedSuit.filter(c => c.pile === -1)
    },
    maxCardsPerSuit() {
      let maxCards = 0
      for (let suit of this.suits)
        maxCards = Math.max(maxCards, this.allCards.filter(c => c.suit === suit).length)
      return maxCards
    }
  },
  methods: {
    ...mapActions(['addCardToPile', 'selectCard', 'navigateCard', 'reset']),
    selectSuit(suit) {
      this.selectedSuit = suit;
    },
    selectCursedItems() {
      this.selectedSuit = undefined
      this.cursedItemsSelected = true
    },
    closeSelected() {
      if (this.freshGame)
        this.$router.replace('/')
      else
        this.$router.go(-1)
    }
  },
  mounted() {
    this.selectedSuit = 'Land'
  }
}
</script>

<style scoped>
.view-wrapper {
  display: block;
  min-height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  height: 100%;
  box-sizing: border-box;
}

.selection-panel {
  display: flex;
  flex-flow: column;
  max-height: 50%;
  min-height: 50%;
  height: 50%;
  box-sizing: border-box;
}

.selection-panel > .suit-selection {
  display: flex;
  justify-content: center;
  min-height: 15%;
  box-sizing: border-box;
}

@media screen and (min-height: 480px) {
  .selection-panel > .suit-selection {
    min-height: 10%;
  }
}

.suit-selector {
  display: flex;
  flex: 1;
  text-align: center;
  border: 1px solid white;
  border-left: none;
  border-top: none;
}

@media screen and (max-height: 480px) {
  .suit-selector {
    font-size: x-small;
  }
}

.suit-selector > label {
  margin: auto;
}

.suit-selector:last-child {
  border-right: none !important;
}

.suit-selector.selected {
  border-bottom: none;
}

.selection-panel > .card-selection {
  display: flex;
  justify-content: flex-start;
  flex: 8;
  padding: 1rem;
}

.card-selection > .card-selector {
}

.pile-panel {
  display: flex;
  min-height: 50%;
  max-width: 100%;
  flex-direction: column;
  background-image: url("~@/assets/wood.png");
  box-sizing: border-box;
  filter: drop-shadow(3px -3px 4px #000000);
}

.pile-panel > .heading {
  display: flex;
  justify-content: space-between;
  max-height: 10%;
  max-width: 100%;
}

.players-panel {
  display: flex;
  flex-grow: 0;
  margin: auto auto auto 0.3rem;
}

.score-panel {
  text-align: right;
  margin-right: 0.3rem;
}

.pile-panel > .pile {
  display: flex;
  flex: 10;
  max-width: 90%;
  justify-content: center;
}

.pile-panel > .text {
  height: 90%;
  min-height: 90%;
  margin: auto;
}
</style>