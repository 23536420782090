<template>
  <div class="item-panel cursed-item-bg">
    <div
        class="item"
        v-for="cursedItem in cursedItemsInDeck"
        :key="'ci_' + cursedItem.number"
        @click="addCursedItem(cursedItem)"
        style="max-width: 10%; overflow: hidden;max-height: 25%;">
      <img
          width="auto"
          height="100%"
          :src="require('@/assets/cards/' + $i18n.locale + '/cursed_items/FantasyRealmsCursedHoard-cards-Front-DE' + cursedItem.number + '.jpg')"
          alt="">
      <div style="white-space: normal;display: flex">{{ $t('cursedItemNames.' + cursedItem.name) }}</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['cursedItems']),
    cursedItemsInDeck() {
      return this.cursedItems.filter(ci => ci.pile < 1)
    },
    cursedItemRows() {
      let res = []
      res.push(this.cursedItems.slice(0, 8))
      res.push(this.cursedItems.slice(8, 16))
      res.push(this.cursedItems.slice(16, 24))
      return res
    }
  },
  methods: {
    ...mapActions(['addCursedItem'])
  }
}
</script>

<style scoped>
.item-panel {
  min-height: 90%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 10%;
}

.item {
  display: flex;
  flex-direction: row;
  font-size: smaller;
  justify-content: center;
  color: black;
  white-space: normal;
  box-shadow: 1px 1px 3px #000000;
  margin: 0.2rem;
}

</style>

